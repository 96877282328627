import { useGetState } from "components/ReduxProvider";
import Dexie, { Table } from "dexie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { snackbar } from "tools/events";
import { MAIN } from "../root/RootRouter.config";
import { useDBLog } from "hooks/useDBLog";
import { useRef, useEffect, useCallback } from "react";

export interface Grammar {
  id: string;
  grammar: string;
  lang: string;
}
export interface CardData {
  id?: number;
  text: string;
  tts?: string;
  ttsVoiceId: string;
  lang: string;
  grammar: readonly Grammar[] | null;
}

const dexieVersion = "1";
// NOTE: once we will need to update version and clear data on users devices, uncomment the code below:
// const storageDexieVersion = window.localStorage.getItem("dexieVerion");
// if (dexieVersion !== storageDexieVersion) {
//   Dexie.delete("myDatabase");
//   const dv = window.localStorage.setItem("dexieVerion", dexieVersion);
//   console.info("deleted old dexie version");
// }

export class MySubClassedDexie extends Dexie {
  cardsData!: Table<CardData>;

  constructor() {
    super("myDatabase");
    this.version(Number(dexieVersion))
      .stores({ cardsData: "++id" })
      .upgrade((trans) => trans.table("cardsData").clear());
  }
}

export const dexie = new MySubClassedDexie();

export const clearIndexDB = () => {
  // dexie.cards.clear(); // offline version
  dexie.cardsData.clear();
};

export const deleteAllIndexedDBs = () => {
  // src: https://stackoverflow.com/a/54764150/844628
  window.indexedDB?.databases().then((r) => {
    for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(String(r[i].name));
  });
};

export const useDexieError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cardsData = useGetState("idbCardsData");
  const dblog = useDBLog();

  const cardsDataRef = useRef<CardData[] | undefined>(undefined);

  useEffect(() => {
    cardsDataRef.current = cardsData;
  }, [cardsData]);

  return useCallback(
    async (error: any) => {
      snackbar(t("Storage error. Try to restart the App and check if you have enough space on your device."), {
        severity: "error",
        autoHide: false
      });
      clearIndexDB();

      dblog("storageError", "Dexie transaction failed. (Alert to user + clearing IndexDB)", {
        error,
        cardsInDB: cardsDataRef.current?.length,
        cardsSizeInKB: Math.round(JSON.stringify(cardsDataRef.current).length / 1024),
        storageEstimate: navigator?.storage?.estimate && (await navigator.storage.estimate())
      });
      navigate(MAIN.url(), { replace: true });
    },
    [dblog, navigate, t]
  );
};
