import React, { useEffect } from "react";
import { Card, Collapse, IconButton, Stack } from "@mui/material";
import styled from "styled-components";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SubscriptionDisplay } from "root/admin/adminUsers/SubscriptionDisplay";
import { AdminUserInfo } from "root/admin/adminUsers/AdminUserInfo";
import { AdminUserActions } from "root/admin/adminUsers/AdminUserActions";
import { AdminUsersSearchQuery$data } from "./__generated__/AdminUsersSearchQuery.graphql";
import { useSearchParams } from "react-router-dom";

export type AdminUserType = NonNullable<
  NonNullable<NonNullable<AdminUsersSearchQuery$data["searchUsers"]["edges"]>[0]>["node"]
>;

const Block = styled.div`
  padding: 20px;
`;
const SCard = styled(Card)`
  margin: 10px;
  padding: 10px;
`;
const Title = styled.div`
  font-weight: bold;
`;

type UserRowProps = {
  user: AdminUserType;
};

const getMostRecentSubscription = (subscriptions: AdminUserType["subscriptions"]) => {
  if (!subscriptions?.length) return undefined;
  const maxTimeToExpire = Math.max(...subscriptions.map((s) => s.timeToExpire));
  return subscriptions.find((sub) => sub.timeToExpire === maxTimeToExpire);
};

const AdminUser: React.FC<UserRowProps> = ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const [searchParams] = useSearchParams();

  // for payout reference link from email
  useEffect(() => {
    if (user && user.email === searchParams.get("search")) setOpen(true);
  }, [searchParams, user]);

  if (!user) return null;

  return (
    <SCard variant="outlined">
      <Stack direction="row" alignItems="center" onClick={() => setOpen(!open)}>
        <IconButton aria-label="expand row" size="small">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>

        <div>
          <Title>{user.email}</Title>
          <div>
            <SubscriptionDisplay subscription={getMostRecentSubscription(user?.subscriptions)} />
          </div>
        </div>
      </Stack>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Block>
          <AdminUserInfo user={user} />

          <AdminUserActions user={user} />
        </Block>
      </Collapse>
    </SCard>
  );
};

export default AdminUser;
