import { isIos } from "tools/device";

const HAPTIC_FEEDBACK_ID = "haptic-feedback-element";
const ANDROID_VIBRATION_DURATION = 15;

const getOrCreateHapticElement = (): HTMLLabelElement => {
  let element = document.getElementById(HAPTIC_FEEDBACK_ID) as HTMLLabelElement;
  if (!element) {
    element = document.createElement("label");
    element.id = HAPTIC_FEEDBACK_ID;
    element.style.display = "none";
    element.innerHTML = '<input type="checkbox" switch style="display: none;" />'; // thanks to switch it do the haptic feedback on ios
    element.addEventListener("click", (e) => e.stopPropagation()); // prevent closing ui elements (like snackbar)
    document.body.appendChild(element);
  }
  return element;
};

export const triggerHaptic = () => {
  if (isIos() || !navigator?.vibrate) {
    const element = getOrCreateHapticElement();
    element.click();
  } else {
    navigator.vibrate(ANDROID_VIBRATION_DURATION);
  }
};
