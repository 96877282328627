import React from "react";
import styled from "styled-components/macro";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Mammoth from "icons/Mammoth";
import Confetti from "react-confetti";
import { useEvent, usePrevious, useWindowSize } from "react-use";
import { EVENT } from "tools/events";
import { bottomPadding, topPadding } from "tools/device";
import { triggerHaptic } from "tools/haptic";

const SSnackbar = styled(Snackbar)<{ anchorOrigin: { vertical?: "top" | "bottom" } }>`
  z-index: 99999;
  @media (max-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    inset: ${({ anchorOrigin }) =>
      anchorOrigin.vertical === "top" ? `${18 + topPadding}px 12px auto` : `auto 12px ${18 + bottomPadding}px`};
    transform: none;
  }
`;
const SAlert = styled(Alert)`
  position: relative;
  width: 100%;
  padding: 14px 20px;
  .MuiAlert-action {
    padding-left: 5px;
  }
  .MuiAlert-message {
    align-self: center;
    width: 100%;
  }
`;
const SMammothIcon = styled(Mammoth)`
  svg {
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }
`;

const SlideTransition = (props) => <Slide {...props} />;

export type SnackbarDetail = {
  text: React.ReactNode;
  severity?: AlertProps["severity"];
  autoHide?: false | number;
  confetti?: boolean;
  bottom?: boolean;
  notDissmissable?: boolean;
};

const SnackbarProvider: React.FC = () => {
  const [snackbar, setSnackbar] = React.useState<SnackbarDetail>();
  const [hiding, setHiding] = React.useState(false);
  const [tooSoonToClose, setTooSoon] = React.useState(false);
  const { width } = useWindowSize();

  const handleSnackbar = React.useCallback((e: CustomEvent<SnackbarDetail>) => {
    setSnackbar(e.detail);
    setHiding(false);
  }, []);
  useEvent(EVENT.snackbar, handleSnackbar);

  const clearAlert = () => {
    if (tooSoonToClose || snackbar?.notDissmissable) return;
    setHiding(true);
  };

  const duration =
    snackbar?.autoHide !== false ? (typeof snackbar?.autoHide === "number" ? snackbar?.autoHide : 7000) : null;

  const open = !!snackbar && !hiding;
  const prevOpen = usePrevious(open);
  React.useEffect(() => {
    if (open && !prevOpen) {
      setTooSoon(true);
      setTimeout(() => setTooSoon(false), 500);
      if (snackbar?.confetti) {
        setTimeout(() => triggerHaptic(), 0);
        setTimeout(() => triggerHaptic(), 150);
        setTimeout(() => window.navigator.vibrate?.(200), 300);
        setTimeout(() => triggerHaptic(), 750);
        setTimeout(() => triggerHaptic(), 900);
      }
    }
  }, [open, prevOpen, snackbar?.confetti]);

  return (
    <>
      <SSnackbar
        anchorOrigin={{ vertical: snackbar?.bottom ? "bottom" : "top", horizontal: "center" }}
        TransitionComponent={SlideTransition}
        open={open}
        autoHideDuration={duration}
        onClose={clearAlert}
      >
        <SAlert
          severity={snackbar?.severity}
          elevation={3}
          style={{ margin: "0 auto" }}
          iconMapping={{
            info: <SMammothIcon />
          }}
          onClick={clearAlert}
          onTouchStart={clearAlert}
        >
          {snackbar?.text}
        </SAlert>
      </SSnackbar>
      {open && snackbar?.confetti && (
        <Confetti
          confettiSource={{ x: 0, y: 0, w: width, h: 150 }}
          numberOfPieces={80}
          gravity={0.15}
          recycle={false}
          opacity={0.7}
          style={{ zIndex: 1300, position: "fixed" }} // right behind snackbar
        />
      )}
    </>
  );
};

export default SnackbarProvider;
