import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { LEARN } from "../MainRouter";
import { HeaderLayout } from "../../../components/header/Header";
import CardStatistics from "../components/CardStatistics";
import CardList from "../components/CardList/CardList";
import { useCounter, useEvent } from "react-use";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import { useLibraryUrl, useMatchMain } from "../../RootRouter";
import HeaderMenu from "../../../components/header/HeaderMenu";
import { useSeeHint } from "../../../components/hint/Hint";
import { Trans } from "react-i18next";
import Quests, { QUESTS_ID } from "./quests/Quests";
import FriendshipChecklist, { useShowFriendshipChecklist } from "./FriendshipChecklist";
import { Paper } from "@mui/material";
import Theme from "./Theme";
import SubsKicker from "./hints/SubsKicker";
import FireStats from "components/fire/FireStats";
import { useSeePickedFewCards } from "components/fire/xpHooks";
import { EVENT } from "../../../tools/events";
import FilterIcon from "icons/FilterIcon";
import StarIcon from "components/other/StarIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBooks } from "@duocards/pro-regular-svg-icons";
import { MAIN } from "../../RootRouter.config";
import { STREAK_FREEZE_EARN_QUEST } from "sharedJs__generated/constants";
import { triggerHaptic } from "tools/haptic";

export const FIVEHINT = "fiveHint"; // indicates end of cards grabbing
export const MANY_UNKNOWN = 10;
export const STARTID = "startId";

const padd = { top: 20, bottom: 10 };

const Wrap = styled.div<{ showCards: boolean }>`
  padding: ${padd.top}px 20px ${padd.bottom}px;
  text-align: center;
  height: calc(${({ showCards }) => (showCards ? "90%" : "100%")} - 76px);
  min-height: 370px;
  display: flex;
  flex-direction: column;
  transition: height 0.3s;
`;
const ButtWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  padding-bottom: 5px;
`;
const SButton = styled(Button)`
  padding: 12px;
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  width: 84%;
  margin: auto;
`;
const NoCards = styled.div`
  color: #aaa;
  line-height: 1.4em;
  font-size: 13px;
  svg {
    fill: #aaa;
    margin-bottom: -3px;
  }
`;
const SPaper = styled(Paper)`
  padding: 15px 15px;
  margin: 15px 0;
`;
const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const SFilterIcon = styled(FilterIcon)`
  width: 15px;
  height: 15px;
  fill: #ccc;
`;
const SLibIcon = styled(FontAwesomeIcon)`
  fill: #ccc;
  font-size: 18px;
  position: relative;
  bottom: 2px;
  padding-left: 2px;
`;
const GreyLink = styled.div`
  margin: 5px;
  cursor: pointer;
  font-size: 95%;
  border-bottom: 1px solid #e8e8e8;
  display: inline-block;
`;
const SStarIcon = styled(StarIcon)`
  position: absolute;
  margin-top: -7px;
  margin-left: -3px;
  top: auto;
  right: auto;
`;

const Main = () => {
  const { hintWasSeen } = useViewerQuery();
  const { deck } = useViewerDecks();
  const [showCards, setShowCards] = React.useState(false);
  const navigate = useNavigate();
  const seeHint = useSeeHint();
  const inMain = useMatchMain();
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const statsRef = React.useRef<HTMLDivElement>(null);
  const statsHeight = React.useRef<number | null>(null);
  const butWrapRef = React.useRef<HTMLDivElement>(null);
  const [updated, { inc: update }] = useCounter();
  const [hideStats, setHideStats] = React.useState(false);
  const showFriendshipChecklist = useShowFriendshipChecklist();
  const libraryUrl = useLibraryUrl();

  const handleCardsClick = () => setShowCards(!showCards);

  useSeePickedFewCards();

  React.useEffect(() => {
    deck && (statsHeight.current = statsRef.current?.offsetHeight || null);
  }, [deck]);

  React.useEffect(() => {
    Number(deck?.stats?.total) >= 5 && seeHint(FIVEHINT);
  }, [seeHint, deck]);

  React.useEffect(() => {
    // if Router end up here with unknown url (like /google_deeplink?...) let's redirect it
    if (!inMain) navigate(MAIN.url(), { replace: true });
  }, [navigate, inMain]);

  const updateMain = React.useCallback(() => update(), [update]);
  useEvent(EVENT.updateMain, updateMain);

  React.useEffect(() => {
    const questsEl = document.getElementById(QUESTS_ID);
    if (!deck || !wrapRef.current || !questsEl || !statsHeight.current) return;
    const newHideStats =
      questsEl.offsetHeight + statsHeight.current + (butWrapRef.current?.offsetHeight || 0) + padd.top + padd.bottom >
      wrapRef.current.offsetHeight;
    setHideStats(newHideStats);
  }, [updated, deck]);

  if (!deck) return null;

  const noCards = deck.stats.unknown === 0;
  const displayNoCards = noCards && hintWasSeen(STREAK_FREEZE_EARN_QUEST);
  const displayStartBtn = !noCards;

  return (
    <HeaderLayout title={<HeaderMenu />} noBack left={<Theme />} right={<FireStats />}>
      <Wrap showCards={showCards} ref={wrapRef}>
        {!hideStats && <CardStatistics ref={statsRef} />}

        <Content>
          {showFriendshipChecklist ? (
            <SPaper>
              <FriendshipChecklist />
            </SPaper>
          ) : (
            <>
              {!hideStats && <div style={{ margin: "auto 0 0" }} />}
              <Quests />
            </>
          )}

          <ButtWrap ref={butWrapRef}>
            {displayStartBtn && (
              <SButton
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(LEARN.url());
                  triggerHaptic();
                }}
                size="large"
                id={STARTID}
              >
                <Trans>Start</Trans>
              </SButton>
            )}

            {displayNoCards && (
              <NoCards>
                <Trans>Time to discover new words</Trans>
                <>
                  <br />
                  <GreyLink style={{ marginTop: 10 }} onClick={() => navigate(libraryUrl)}>
                    <Trans>Add more cards from library</Trans> <SLibIcon icon={faBooks} />
                  </GreyLink>
                  <br />
                  <GreyLink onClick={() => navigate(LEARN.url({ [LEARN.popupKnownParam]: true }))}>
                    <Trans>Test me from known cards</Trans> <SFilterIcon />
                    <SStarIcon />
                  </GreyLink>
                </>
              </NoCards>
            )}
          </ButtWrap>
        </Content>
      </Wrap>

      <CardList onClick={handleCardsClick} showCards={showCards} setShowCards={setShowCards} />
      <SubsKicker />
    </HeaderLayout>
  );
};

export default Main;
